<template>
<!-- 联系我们 -->
  <div class="about">
    <div class="nuox-about-header">
      <img src="http://39.104.95.82:8087/img/header/7.jpg" >
    </div>
    <div class="module-content">
      <div class="nuox-breadcrumb nuox-top">
        <span class="nuox-breadcrumb-item"><router-link tag="span" :to="$t('lang.headerMenus[0].pageSrc')">{{ $t("lang.headerMenus[0].name") }}</router-link></span>
        <span class="iconfont icon-dian"></span>
        <span class="nuox-breadcrumb-item">{{ $t("lang.headerMenus[8].name") }}</span>
      </div>
      <div class="module-line"></div>
      <div class="nuox-content-title">
            <div class="title-name">
              {{ $t("lang.headerMenus[8].name") }}
              <div class="xian"></div>
            </div>
      </div>
    </div>
    <div class="nuox-un-module">
        <el-row>
          <el-col :span="8"><img class="contact_img" :src="relation.imgFm"></el-col>
          <el-col :span="16">
            <div class="contact_info">
                <el-row>
                   <el-col :span="7" class="contact_qrcode"><img class="contact_qrcode_img" :src="relation.imgQrcode"></el-col>
                    <el-col :span="17">
                      <div class="contact_content" v-if="$i18n.locale=='zh'">
                          <div class="tt">{{relation.nameZh}}</div>
                          <div class="uu">电话：{{relation.phone}}</div>
                          <div class="uu">邮箱：{{relation.email}}</div>
                          <div class="uu">地址：{{relation.addressZh}}</div>
                      </div>
                      <div class="contact_content" v-if="$i18n.locale=='en'">
                          <div class="tt">{{relation.nameEn}}</div>
                          <div class="uu">tel: {{relation.phone}}</div>
                          <div class="uu">E-mail: {{relation.email}}</div>
                          <div class="uu">Add.: {{relation.addressEn}}</div>
                      </div>
                    </el-col>
                </el-row>
            </div>
          </el-col>
        </el-row>
        <div class="module-content">
          <div class="contact_branch_office">
              <el-row>
                  <el-col :span="8" v-for="(item,index) in groups" :key="index">
                      <div class="text-center contact_branch_item text-blue"><i class="iconfont icon-dingwei"></i></div>
                      <div class="text-center contact_branch_item text-bold" v-if="$i18n.locale=='zh'">{{item.nameZh}}</div>
                      <div class="text-center contact_branch_item text-bold" v-if="$i18n.locale=='en'">{{item.nameEn}}</div>
                      <div class="text-center contact_branch_item text-a" v-if="$i18n.locale=='zh'">地址：{{item.addressZh}}</div>
                      <div class="text-center contact_branch_item text-a" v-if="$i18n.locale=='en'">Add.：{{item.addressEn}}</div>
                  </el-col>
              </el-row>
          </div>
        </div>
    </div>
     <div class="module-content">
         <div class="module-line"></div>
     </div>
  </div>
</template>
<script>
export default {
  data:function() {
    return {
        menus:[],
        checkId:32,
        checkChildrenId:0,
        groups:[],
        pageNum:1,
        pageCount:30,
        relation:{nameZh:'',nameEn:'',phone:'',email:'',addressZh:'',addressEn:''}
    };
  },
  mounted(){
    this.init();
    this.relationFun();
  },
  methods:{
    async init(){
      var locale =  this.$i18n.locale;
      var baseUrl = this.$baseURL;
      var that = this;
       var pageNum = this.pageNum;
      var pageCount = this.pageCount;
      const { data: res } = await this.$http.get(
        "webSite/nuox_subsidiary?pageNum="+pageNum+"&pageCount="+pageCount
      );
      if(res.code==0){
        var data = res.data.data;
        var groups = [];
        for(var key in data){
          if(data[key].pxLevel>0){
               var d1 = {};
                d1.nameZh = data[key].subsidiaryNameZh;
                d1.nameEn = data[key].subsidiaryNameEn;
                d1.addressZh = data[key].addressZh;
                d1.addressEn = data[key].addressEn;
                groups.push(d1);
          }
        }
        that.groups=groups;
      }
    },
    async relationFun(){//加载公司信息
      var that = this;
       var baseUrl = this.$baseURL;
      const { data: res } = await this.$http.get(
        "webSite/nuox_relation?pageNum=1&pageCount=1"
      );
      if(res.code==0){
        var datas = res.data.data;
        if(datas.length>0){
          var data = datas[0];
          var imgFm = '';
          var imgQrcode = '';
          var fmAttList = data.fmAttList;
          var ewmAttList = data.ewmAttList;
          for(var index in fmAttList){
            imgFm=baseUrl+fmAttList[index].attPath+fmAttList[index].attRealName;
            break;
          }
           for(var index in ewmAttList){
            imgQrcode=baseUrl+ewmAttList[index].attPath+ewmAttList[index].attRealName;
            break;
          }
          that.relation.imgFm = imgFm;
          that.relation.imgQrcode = imgQrcode;
          that.relation.nameZh = data.relationNameZh;
          that.relation.nameEn = data.relationNameEn;
          that.relation.addressZh = data.relationAddressZh;
          that.relation.addressEn = data.relationAddressEn;
          that.relation.phone = data.relationPhone;
          that.relation.email = data.relationEmail;
        }
      }
    },
    checknav(e){
      if(e.pageSrc){
          this.$router.push({ 
            path: e.pageSrc,
            query: {}
          })
      }
      this.checkId = e.id;
    },
    checknavchildren(item,item2){
      this.checkId=item.id;
      this.checkChildrenId=item2.id;
    }
  }
}
</script>
<style>
@import '../assets/css/about.css';
.nuox-top{
    margin-top: 50px;
}
.contact_img{
  height: 300px;
  width: 100%;
}
.contact_info{
  background-color: #E7EEF4;
  height: 200px;
  margin-top: 50px;
}
.contact_qrcode{
  text-align: right;
}
.contact_qrcode_img{
  width: 150px;
  margin-top: 25px;
}
.contact_content{
  padding: 25px 70px;
}
.contact_content .tt{
    font-size: 15px;
    font-weight: bold;
    font-family: "Microsoft YaHei";
    height:37px ;
    line-height: 37px;
}
.contact_content .uu{
    height:37px ;
    line-height: 37px;
}

.contact_branch_office .el-col{
  padding: 10px;
  border-left: 1px solid #E7EEF4;
  border-right:1px solid #E7EEF4;
  margin-bottom: 30px;
}
.contact_branch_item{
  height: 30px;
  line-height: 30px;
}
</style>